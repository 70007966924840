@import 'src/utils/utils';

.tags {
  margin-top: 25px;
  margin-right: 16px;
  margin-left: 16px;
  margin-bottom: 25px;

  @media(min-width: $lg) {
    margin-top: 30px;
    margin-right: 0;
    margin-bottom: 30px;
    margin-left: 0;
  }
  @media(min-width: $xl) {
    @include box;
  }

  &__header {
    @include zero;
    @include Text-16-bold;
    color: $Main-1;
    margin-bottom: 15px;

    @media(min-width: $lg) {
      @include Text-18-bold;
    }
  }

  &__form {
    display: flex;
    // margin-top: 12px;
    margin-right: -16px;
    // margin-bottom: 12px;
    margin-left: -16px;
    padding-right: 16px;
    padding-left: 16px;
    overflow-x: auto;
    white-space: nowrap;
    gap: 6px;
  
    @media(min-width: $md) {
      margin-right: -16px;
      margin-left: -16px;
    }
    @media(min-width: $lg) {
      margin-right: unset;
      margin-left: unset;
      padding-right: unset;
      padding-left: unset;
      white-space: wrap;
      flex-wrap: wrap;
      overflow: unset;
    }
  }

  input {
    display: none;

    &:checked + label {
      background-color: $Corporate-2;
      color: $Main-2;

      &:hover {
        @media(min-width: $lg) {
          background-color: $Corporate-3;
        }
      }
    }
  }

  label {
    @include zero;
    @include Text-14-reg;
    padding-top: 6px;
    padding-right: 12px;
    padding-bottom: 6px;
    padding-left: 12px;
    color: $Main-1;
    border-radius: 20px;
    background-color: $Main-2;
    transition: 0.3s;
    cursor: pointer;

    &:hover {
      @media(min-width: $lg) {
        color: $Corporate-2;
      }
    }
  }
}